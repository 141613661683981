export const compMenus = [
    {
        // 企业信息  privi: 1001,
        title: '企业信息', key: 'comp_info',icon:'icon iconfont icon-qiyexinxi', children: [
            { title: '基本信息', key: '/comp/base',icon:'icon iconfont icon-sssss' },
            { title: '银行信息', key: '/comp/bank',icon:'icon iconfont icon-ssssss' },
            { title: '管理员信息', key: '/comp/legal',icon:'icon iconfont icon-sssqyxx' },
            { title: '企业资质', key: '/comp/zizhi', compTypes: [1,2,3], icon:'icon iconfont icon-sssqyxx' }
        ]
    }, {
        title: '企业管理', key: 'comp_m',icon:'icon iconfont icon-ssssss', children: [
            { title: '职务/权限管理', key: '/comp/contact',icon:'icon iconfont icon-tongxunlu-hoverqyxx', privi: 1003 },
            { title: '职务/权限设置', key: '/comp/roles',icon:'icon iconfont icon-quanxiancopyqyxx', },
            { title: '辅助操作授权日志', key: '/comp/authorLog',icon:'icon iconfont icon-zu14621', }
        ]
    },
];